/* jshint ignore: start */
// mixin for centralized definitions for tour steps
export const TourSteps = {
  data() {
    return {
      // help button tour
      helpTourName: "HelpButton",
      helpTourVersion: "1.0",
      helpTourSteps: [
        {
          target: "#HelpTourBtn",
          content: `<strong>You're all set!</strong>
                    <br/>
                    We'll provide some more help and tips as we go.
                    <br/>
                    In the meantime, click here to launch help whenever you need!`,
          params: {
            placement: "bottom-end",
            enableScrolling: true
          }
        }
      ],
      // other tours -- alphabetical
      // CoverMap
      coverMapTourSteps: [
        {
          target: "#CoverMapStepLayersMenu",
          content: `<strong>Map Layers</strong>
                    <br/>
                    Click here for map settings and layer controls.`,
          params: {
            placement: "right",
            modifiers: {
              offset: {
                enabled: true,
                offset: "20px, 0"
              }
            }
          }
        },
        {
          target: "#CoverMapStepSpeedDial",
          content: `<strong>Location Controls</strong>
                    <br/>
                    These buttons help set your current map location or drop a pin for a new observation.`,
          params: {
            placement: "right",
            modifiers: {
              offset: {
                enabled: true,
                offset: "-50px, 0"
              }
            }
          }
        }
      ],
      // CoverMapSidebarToggle
      coverMapRightSidebarTourSteps: [
        {
          target: "#CoverMapRightSidebar",
          content: `Click here to show or hide the sidebar panel.`,
          params: {
            placement: "left"
          }
        }
      ],
      // DashboardSidebar
      dashboardSidebarTourSteps: [
        {
          target: "#SidebarMainMenu",
          content: `Use the main menu to jump to different activities.`,
          params: {
            placement: "right-start",
            modifiers: {
              offset: {
                enabled: true,
                offset: "50px, 0"
              }
            }
          }
        },
        {
          target: "#SidebarMainMenu",
          content: `<p>You should start by reviewing <br/><strong><i class="md-icon md-icon-font">work</i> Your Mission</strong></p>
                    <p>Then dive into the video content and map exploration!</p>
                    <p>Your ultimate job will be to create new land-use planning scenarios and learn about land-use issues.</p>`,
          params: {
            placement: "right-start",
            modifiers: {
              offset: {
                enabled: true,
                offset: "90px, 0"
              }
            }
          }
        }
      ],
      // ExploreTimeSlider
      exploreTimeSliderSteps: [
        {
          target: "#ExploreTimeSliderContainer",
          content: `<p>Use the timeline controls to play through your simulation.</p>`,
          params: {
            placement: "top",
            enableScrolling: false
          }
        }
      ],
      // Observations
      observationsTourSteps: [
        {
          target: "#ObservationsNewBtn",
          content: `<p>Click here to launch the map and record a new observation.</p>`,
          params: {
            placement: "bottom",
            enableScrolling: false
          }
        }
      ],
      // ObservationsFilter
      observationsFilterTourSteps: [
        {
          target: "#ObservationsFilterBtn",
          content: `<p>Click here to sort your observations.</p>`,
          params: {
            placement: "bottom",
            enableScrolling: false
          }
        }
      ],
      // RightSidebarClimatePanel
      rightSidebarClimatePanelSteps: [
        {
          target: "#ClimatePanelTitle",
          content: `This panel shows climate data over the course of your simulation`,
          params: {
            placement: "bottom",
            enableScrolling: false
          }
        },
        {
          target: "#ClimatePanelTemperatureMapLayerBtn",
          content: `<p>Click here to set the map data layer.</p>`,
          params: {
            placement: "left",
            enableScrolling: false
          }
        }
      ],
      // RightSidebarCoverIndicators
      rightSidebarCoverIndicatorsSteps: [
        {
          target: "#CoverIndicatorCardEnvironmental",
          content: `<strong>Environmental Indicators</strong>
                    <br/>
                    Track the environmental health of your landscape by following these indicators.`,
          params: {
            placement: "bottom-start",
            enableScrolling: false
          }
        },
        {
          target: "#CoverIndicatorCardSocio-Economic",
          content: `<strong>Socio-Economic Indicators</strong>
                    <br/>
                    Track social and economic indicator health with these indicators.`,
          params: {
            placement: "bottom-start",
            enableScrolling: false
          }
        },
        {
          target: "#CoverIndicatorBioticCarbon",
          content: `Click on any indicator to see more details`,
          params: {
            placement: "left",
            enableScrolling: false
          }
        }
      ],
      // RightSidebarCoverIndicatorGoal
      rightSidebarCoverIndicatorGoalSteps: [
        {
          target: "#CoverIndicatorGoalStatusBioticCarbon",
          content: `See the success of your goals at a glance with these status lights`,
          params: {
            placement: "left",
            enableScrolling: false,
            modifiers: {
              offset: {
                enabled: true,
                offset: "0, 20px"
              }
            }
          }
        }
      ],
      // RightSidebarLandscapeSummary
      rightSidebarLandscapeSummarySteps: [
        {
          target: "#LandscapeSummaryPanelTitle",
          content: `This panel provides a summary of landscape and land-use for your study area over the course of your simulation`,
          params: {
            placement: "bottom",
            enableScrolling: false
          }
        },
        {
          target: "#LandscapeSummaryPanelTitle",
          content: `<p><i class="fas fa-th-list"></i> shows the landscape and land-use in a summary table</p>`,
          params: {
            placement: "left",
            enableScrolling: false,
            modifiers: {
              offset: {
                enabled: true,
                offset: "45px, 0"
              }
            }
          }
        },
        {
          target: "#LandscapeSummaryPanelTitle",
          content: `<p><i class="fas fa-chart-pie"></i> shows the landscape and landuse proportional composition</p>`,
          params: {
            placement: "left",
            enableScrolling: false,
            modifiers: {
              offset: {
                enabled: true,
                offset: "45px, 0"
              }
            }
          }
        },
        {
          target: "#LandscapeSummaryPanelTitle",
          content: `<p><i class="fas fa-tasks"></i> shows land-use management and beneficial management practices</p>`,
          params: {
            placement: "left",
            enableScrolling: false,
            modifiers: {
              offset: {
                enabled: true,
                offset: "45px, 0"
              }
            }
          }
        },
        {
          target: "#LandSummaryMapLayerBtn",
          content: `<p>Click here to set the map data layer to Land Use</p>`,
          params: {
            placement: "bottom",
            enableScrolling: false
          }
        }
      ],
      // RightSidebarLandscapeSummaryEdit
      rightSidebarLandscapeSummaryEditSteps: [
        {
          target: "#LandSummaryEditBtn",
          content: `<p>Click here to edit your planned land use allocations</p>`,
          params: {
            placement: "bottom",
            enableScrolling: false
          }
        }
      ],
      // TopNavbarScenario
      topNavbarScenarioTourVersion: "1.0",
      topNavbarScenarioTourSteps: [
        {
          target: "#TopNavbarScenarioBtn",
          content: `Click here to launch a scenario!`,
          params: {
            placement: "bottom",
            enableScrolling: false
          }
        }
      ],
      userScenariosTourSteps: [
        {
          target: "#UserScenariosNewScenarioBtn",
          content: `<p>Click here to create a new Historic, Business as Usual or Land Use plan scenario.</p>`,
          params: {
            placement: "bottom",
            enableScrolling: false
          }
        }
      ],
      userScenariosFilterTourSteps: [
        {
          target: "#UserScenariosFilterBtn",
          content: `<p>Click here to sort your scenarios.</p>`,
          params: {
            placement: "bottom",
            enableScrolling: false
          }
        }
      ]
    };
  }
};
