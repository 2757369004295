<template>
  <div class="text-center chart-container">
    <div
      class="chart-holder"
      @click="showIndicatorDetails"
    >
      <status-indicator
        v-if="indicatorGoalData !== null && EXPLORE_MODE !== 'create'"
        :id="`CoverIndicatorGoalStatus${indicator.name.replace(/\s/g, '')}`"
        :status="indicatorGoalStatus"
        :pulse="goalStatusPulse"
        class="statusIndicator"
      />
      <chartist
        :data="indicatorChartData"
        :options="indicatorChartOptions"
        type="Pie"
      />
      <chartist
        v-if="indicatorGoalData !== null"
        :data="indicatorChartGoalData"
        :options="indicatorChartGoalOptions"
        type="Pie"
      />
      <md-icon
        :md-src="`/img/${indicator.icon}`"
        class="chart-icon"
        :class="{'larger-icon': largeIcon}"
      />
    </div>
    <md-tooltip md-direction="bottom">
      {{ indicator.name }}
    </md-tooltip>
    <span
      v-if="activity === 'view'"
      class="md-caption text-no-padding text-center"
    >
      {{ indicatorValWithUnits }}
    </span>
    <span
      v-else
      class="md-caption text-no-padding text-center"
    >
      {{ indicator.simple_units }}
    </span>
    <div
      v-if="['create','update'].includes(activity)"
      class="md-layout-item md-size-100"
    >
      <span class="md-caption text-no-padding">
        {{ indValTitle }}: {{ indicatorValAbbreviated }}
        <br>
        Goal: {{ goalAsIndicatorUnits }}
      </span>
      <slider
        v-model="indicatorGoalData"
        :disabled="!allowEdits"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { Slider } from '@/components';
import { Scenario } from "./mixins/Scenario";
import { Indicators } from "./mixins/Indicators";
import { LandTypes } from "./mixins/LandTypes";
import * as fillDonut from "chartist-plugin-fill-donut";
import { debounce } from "debounce";
import { StatusIndicator } from 'vue-status-indicator';
export default {
  name: "CoverIndicator",

  components: {
    Slider,
    StatusIndicator
  },
  mixins: [Indicators, Scenario, LandTypes], // for saving updated scenario to db

  props: {
    activity: {
      // user activity when viewing the indicator
      type: String,
      default: "view",
      validator: (prop) => [
        'view', // view existing scenario
        'create', // create new scenario
        'update', // update scenario
      ].includes(prop)
    },
    scenario: {
      type: Object,
      required: true,
    },
    indicator: {
      type: Object,
      required: true
    },
    indicatorVal: {
      type: Array, // array of 2 numbers [indexValue (0-100), actualValue (in indicator.units)]
      required: true
    },
    year: {
      type: Number, // the current year of the simulation
      default: -1
    },
    showAllUnits: {
      type: Boolean,
      default: false
    },
    largeIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      indicatorGoalData: null, // used to actively set the indicator goal on a new scenario
      goalStatusPrevious: null,
      goalStatusPulse: false
    }
  },

  computed: {
    ...mapGetters([
      types.getters.EXPLORE_MODE,
      types.getters.SIDEBAR_CONTENT_OPEN
    ]),

    indValTitle() {
      if (this.EXPLORE_MODE === 'create') {
        return 'Baseline';
      }
      const label = this.year !== -1 ? `${this.year} Value` : `Value`;
      return label;
    },
    indicatorValPercent() {
      // return the percentage based value for the indicator
      return +this.indicatorVal[0].toFixed(2); // note plus sign drops extra zeros from end
    },
    indicatorValActual() {
      // return the actual indicator val in base units
      return this.indicatorVal[1];
    },
    indicatorValConverted() {
      // apply unit conversion and return the actual val in simple units
      return this.indicatorValActual * this.indicator.conversion;
    },
    indicatorValAbbreviated() {
      // abbreviate number with k, M, B, T symbols
      return this.$Helpers.abbreviateNumber(this.indicatorValConverted);
    },
    indicatorValWithUnits() {
      // return string with the indicator value and units
      let val = '';
      if (this.indicator.simple_units === '$') {
        val = `$${this.indicatorValAbbreviated} CAD`;
      } else if (this.indicator.simple_units === '%') {
        val = `${this.indicatorValPercent}%`;
      } else {
        val = `${this.indicatorValAbbreviated} ${this.indicator.simple_units}`;
      }
      // also add the advanced units if applicable
      if (this.showAllUnits && this.indicator.simple_units !== this.indicator.units) {
        val = `${this.$Helpers.abbreviateNumber(this.indicatorValActual)} ${this.indicator.units} (${val})`;
      }
      return val;
    },
    goalAsIndicatorUnits() {
      // return a goal value represented as actual indicator units instead of %
      if (this.indicator.simple_units === '%') {
        return this.indicatorGoalData;
      }
      // compute the goal as actual and return
      // todo a better way to handle indicators that have value of 0
      // prevent divide by 0, set min values for indicator actual and percent to 1
      return this.$Helpers.abbreviateNumber(Math.max(this.indicatorValConverted, 1) / Math.max(this.indicatorValPercent, 1) * this.indicatorGoalData);
    },
    indicatorChartDataClass() {
      // enviro vs econo colors for charts
      if (this.indicator.type === 'Environmental') {
        return "ct-chart-data-enviro";
      } else if (this.indicator.type === 'Indigenous') {
        return "ct-chart-data-indigenous";
      }
      return "ct-chart-data-econo";
    },
    indicatorChartData() {
      return {
        labels: ["", ""],
        series: [
          {
            value: this.indicatorValPercent,
            className: this.indicatorChartDataClass //this.indicator.className
          },
          {
            value: 100 - this.indicatorValPercent,
            content: '',
            className: "ct-fill-donut"
          }
        ]
      };
    },
    indicatorChartOptions() {
      return {
        donut: true,
        donutWidth: 16,
        total: 200,
        startAngle: 270,
        showLabel: false,
        plugins: [fillDonut({ items: [{ content: '' }] })]
      };
    },
    indicatorChartBgData() {
      return {
        labels: ["", ""],
        series: [
          {
            value: 100,
            className: "ct-chart-bg-gray"
          }
        ]
      };
    },
    indicatorChartBgOptions() {
      return {
        donut: true,
        donutWidth: 16,
        startAngle: 270,
        total: 200,
        showLabel: false
      };
    },
    indicatorGoalStatus() {
      // get the current status of a goal
      let val1 = this.indicatorValPercent,
        val2 = this.indicatorGoalData;
      if (this.indicator.target === "low") {
        // low values are better - invert calculation
        val1 = val2;
        val2 = this.indicatorValPercent;
      }
      if (val1 >= val2) {
        return "positive";
      } else if (val1 >= (val2 - 5)) {
        return "intermediary";
      } else if (val1 >= (val2 - 10)) {
        return "intermediary";
      }
      return "negative";
    },
    indicatorChartGoalData() {
      return {
        labels: ["", ""],
        series: [
          {
            value: this.indicatorGoalData,
            className: "ct-chart-chart-goal"
          }
        ]
      };
    },
    indicatorChartGoalOptions() {
      return {
        donut: true,
        donutWidth: 4,
        startAngle: 270,
        total: 200,
        showLabel: false
      };
    }
  },

  watch: {
    indicatorGoalData(newVal, oldVal) {
      if (!isNaN(newVal) && this.ACTIVE_SCENARIO !== null && 'goals' in this.ACTIVE_SCENARIO) {
        if (this.EXPLORE_MODE === 'create') {
          // working on a new scenario
          const _goals = JSON.parse(JSON.stringify(this.ACTIVE_SCENARIO.goals));
          _goals[this.indicator.name] = parseFloat(this.indicatorGoalData);
          this.UPDATE_ACTIVE_SCENARIO({ goals: _goals });
        } else if (oldVal !== null) {
          // updating goal on existing scenario in view or edit mode
          this.debounceGoalUpdate();
        }
      }
    },
    indicatorGoalStatus(newVal) {
      // set a pulse whenever a goal status changes
      if (this.goalStatusPrevious !== newVal) {
        this.goalStatusPrevious = this.indicatorGoalStatus;
        this.goalStatusPulse = true;
        setTimeout(this.resetGoalStatusPulse, 1000);
      }
    },
    SIDEBAR_CONTENT_OPEN(newVal) {
      // need to watch the sidebar in case the goal is updated from the indicator details panel
      // the issue is that the slider v-model conflicts with the updates from the details panel if we don't trigger the update like this
      if (newVal === false) {
        this.indicatorGoalData = this.indicatorGoal();
      }
    }
  },

  mounted() {
    // initialize the goal from passed props to data
    this.indicatorGoalData = this.indicatorGoal();
  },

  methods: {
    ...mapActions([
      types.actions.SET_INDICATOR,
      types.actions.TOGGLE_SIDEBAR_CONTENT,
      types.actions.SET_SIDEBAR_CONTENT_TYPE,
      types.actions.UPDATE_ACTIVE_SCENARIO
    ]),

    showIndicatorDetails() {
      // launch the sidebar if it's not open
      if (!this.SIDEBAR_CONTENT_OPEN) {
        this.SET_SIDEBAR_CONTENT_TYPE('indicator');
        this.SET_INDICATOR(this.indicator);
        this.TOGGLE_SIDEBAR_CONTENT();
      }
    },
    indicatorGoal() {
      // get the indicator goal from the scenario
      if (this.showGoals) {
        if (this.indicator.name in this.scenario.goals) {
          // goal exists in the scenario - use it
          return parseFloat(this.scenario.goals[this.indicator.name]);
        }
        // else default to the indicator goal from region config
        return parseFloat(
          this.$Region.indicators.find(ind => ind.name === this.indicator.name).goal
        );
      }
      return null;
    },
    resetGoalStatusPulse() {
      // turn the pulse off on the goalStatus
      this.goalStatusPulse = false;
    },
    debounceGoalUpdate: debounce(function () {
      // scenario updates when goal slider changes
      if (!isNaN(this.indicatorGoalData)) {
        const _goals = JSON.parse(JSON.stringify(this.ACTIVE_SCENARIO.goals));
        _goals[this.indicator.name] = parseFloat(this.indicatorGoalData);
        this.UPDATE_ACTIVE_SCENARIO({ goals: _goals });
        this.saveScenarioUpdatesToDB(this.ACTIVE_SCENARIO, ["goals"], false);
      }
    }, 500),
  }
};
</script>

<style lang="scss" scoped>
/deep/ {
  // status indicator settings
  --status-indicator-size: 12px;
  --status-indicator-color-positive: rgb(0, 201, 7);
  --status-indicator-color-intermediary: rgb(255, 170, 0);
  --status-indicator-color-negative: rgb(255, 0, 0);
}
.text-center {
  text-align: center;
}
.md-caption {
  color: $white-color !important;
  line-height: 14px;
  display: inline-block;
}
.chart-container {
  padding: 2px;
  margin: 0 0 15px 0;
  min-height: 90px;
  cursor: pointer;
}
.chart-holder {
  position: relative;
  min-height: 50px;
  margin-bottom: -30%;
}
.chart-holder > * {
  position: absolute;
  top: 0;
}
.statusIndicator {
  left: 4px;
  top: 2px;
}
.chart-icon {
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $white-color;
  font-size: inherit !important;
  width: 1em !important;
  height: 1em !important;
  min-width: 24px !important;
  min-height: 24px !important;
}
.larger-icon {
  width: 3em !important;
  height: 3em !important;
}
</style>
<style lang="scss">
.ct-chart-bg-gray {
  stroke: $gray-light;
}
.ct-chart-chart-goal {
  stroke: #fff;
  opacity: 0.4;
  margin-top: -40px;
}
.ct-chart-data-enviro {
  stroke: rgb(1, 168, 76);
}
.ct-chart-data-econo {
  stroke: rgb(1, 96, 168);
}
.ct-chart-data-indigenous {
  stroke: rgb(250, 113, 1);
}
.ct-fill-donut {
  stroke: $gray-light;
  opacity: 0.7;
}
</style>
