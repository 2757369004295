/* jshint ignore: start */
// mixin for some scenario handling
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { User } from "@/pages/Dashboard/Components/mixins/User";
export const Scenario = {
  mixins: [User], // user profile methods
  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      types.getters.USER_SCENARIOS,
      types.getters.LAST_SCENARIO_PATCH,
      types.getters.ACTIVE_SCENARIO,
      types.getters.ACTIVE_RUNID,
      types.getters.SCENARIO_CONNECTION_ACTIVE,
    ]),
  },

  methods: {
    ...mapActions([
      types.actions.OPEN_SCENARIO_COMPUTING_DIALOG,
      types.actions.LOAD_USER_SCENARIOS,
      types.actions.SET_LAST_SCENARIO_PATCH,
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.TOGGLE_REFRESH_SCENARIO,
      types.actions.NEW_ACTIVE_RUNID,
      types.actions.SET_ACTIVE_RUNID_NEW_SCENARIO_ID,
      types.actions.SCENARIO_UNDOS_PUSH,
      types.actions.CLOSE_SIDEBAR_CONTENT,
      types.actions.CLEAR_ACTIVE_HINT,
    ]),
    isSystemScenario(scenario) {
      // todo this function could be dry'er.. it's sprinkled in a bunch of places that could all use this mixin instead
      // return matching system scenario from region config
      return this.$Region.system_scenarios.find(
        (scen) => scen.type == scenario.type
      );
    },
    saveScenarioUpdatesToDB(scenario, scenarioKeys, runSimulation = false) {
      // save an updated existing scenario to database, optionally run simulation
      // sends data to the simulation/PATCH method on the API
      // scenario = scenario object
      // scenarioKeys = array of fields to update in database
      // runSimulation = whether or not to rerun the simulation after saving

      // clear any hints
      this.CLEAR_ACTIVE_HINT();

      // setup dynamo payload
      const payload = {
        UserTransaction: "scen" + scenario.id.toString(),
      };
      const updates = {};
      scenarioKeys.forEach((scenarioKey) => {
        if (scenarioKey === "allocations") {
          updates.allocations = this.$Helpers.mapScenAllocations(
            scenario.allocations
          );
        } else {
          updates[scenarioKey] = scenario[scenarioKey];
        }
      });
      payload.UpdatePayload = JSON.stringify(updates).replace(/"/g, "'");

      if (JSON.stringify(payload) !== this.LAST_SCENARIO_PATCH) {
        // track last patch
        this.SET_LAST_SCENARIO_PATCH(JSON.stringify(payload));

        // sanitize the payload
        this.$Helpers.sanitizeDBPayload(payload);

        // some tracking for checking points later
        const pointsEligible = this.scenarioPointsEligible(
          this.USER_SCENARIOS,
          scenario.type,
          scenario.studyArea,
          scenario.climate,
          scenario.id,
          scenario.numRunsCompleted
        );
        const runTimeEstimate = scenario.type === "Historic" ? 150 : 40; // max estimated runtime length in seconds

        // Push to the DB
        this.$Auth.currentSession().then((authData) => {
          const username = authData.accessToken.payload.username;
          payload.UserName = username;
          const path = "/simulation/" + username;
          const myInit = {
            body: payload,
            headers: { Authorization: authData.idToken.jwtToken },
          };
          this.$API
            .patch("api", path, myInit)
            .then(() => {
              // Refresh the scenario data or error
              // TODO: Notify error if scenario data were not successfully pushed
              this.LOAD_USER_SCENARIOS({ vm: this });

              // run the simulation if requested
              if (runSimulation) {
                // Initiate Lambda Function once the data transfer is complete

                // get a runid
                // DashboardLayout.vue will automatically subscribe and start listening for message for this runID
                this.NEW_ACTIVE_RUNID();
                this.SET_ACTIVE_RUNID_NEW_SCENARIO_ID(null); // this is an existing scenario

                // launch collecting data animation
                this.OPEN_SCENARIO_COMPUTING_DIALOG(runTimeEstimate);

                // get baserasterpath
                let baseRasterPath = this.$Region.base_raster_path;
                if (this.isSystemScenario(scenario)) {
                  baseRasterPath = this.isSystemScenario(scenario)
                    .base_raster_path;
                }
                const scenarioInit = {
                  body: {
                    UserTransaction: payload.UserTransaction,
                    UserSub: this.$Auth.user.attributes.sub,
                    runID: this.ACTIVE_RUNID,
                    baseRaster: baseRasterPath,
                    userBucket: this.$Bucket,
                    weights: JSON.stringify(this.$Region.simulation_weights),
                    allocationAttemptsCutoff: this.$Region
                      .allocation_attempts_cutoff,
                    allocationEfficiencyCutoff: this.$Region
                      .allocation_efficiency_cutoff,
                    ltTypes: JSON.stringify(this.$Region.lt_types).replace(
                      /"/g,
                      "'"
                    ),
                    cellSize: this.$Region.cell_size,
                    indCellSize: this.$Region.ind_cell_size,
                    climateTransitions: JSON.stringify(
                      this.$Helpers.getScenClimateTransitions(
                        this.$Region.climateModuleActive,
                        this.$Region.climateScenarios,
                        this.ACTIVE_SCENARIO.climate
                      )
                    ).replace(/"/g, "'"),
                    climateIndicators: JSON.stringify(
                      this.$Helpers.getScenClimateIndicatorDetails(
                        this.$Region.climateModuleActive,
                        this.$Region.climateScenarios,
                        this.$Region.climateIndicators,
                        this.ACTIVE_SCENARIO.climate
                      )
                    ).replace(/"/g, "'"),
                  },
                  headers: { Authorization: authData.idToken.jwtToken },
                };
                const waitingConnectionPostScenario = () => {
                  // make sure the IoT is connected and listening before posting in case the scenario returns very quickly
                  if (!this.SCENARIO_CONNECTION_ACTIVE) {
                    setTimeout(waitingConnectionPostScenario, 200);
                  } else {
                    this.$API.post("api", path, scenarioInit).then(() => {
                      // processing results will automatically be detected by IoT subscription in DashboardLayout.vue
                      // set the scenario undos
                      this.SCENARIO_UNDOS_PUSH(scenario);
                    });
                    // give the user some points!
                    if (pointsEligible) {
                      this.addUserPoints(this.$Region.awardPoints.scenarioRun);
                    }
                  }
                };
                waitingConnectionPostScenario();
              }
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.log(error);
            });
        });

        // notify update
        if (runSimulation) {
          const msg = `Your scenario was updated and is being re-calculated.`;
          this.$notify({
            message: msg,
            icon: "memory",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          // update runs completed tracker.
          // NOTE it's possible this could get out of sync with DB if there are errors running, but it shouldn't be a big deal.  Used here only to prevent excess points being awarded
          if (!("numRunsCompleted" in scenario)) {
            scenario.numRunsCompleted = 1;
          }
          scenario.numRunsCompleted += 1;
        }

        // analytics tracking
        const label = `${scenario.type}, ${scenario.studyArea}, ${scenario.climate}`;
        this.$Helpers.gtagEvent(this, `edit_scenario`, `scenarios`, label);
      }
    },
    scenarioComplete(scen) {
      // notification when scenario complete
      if (scen.incompleteRun) {
        this.$notify({
          message: 'There was an error running scenario "' + scen.name + '"',
          icon: "error_outline",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      } else {
        this.$notify({
          message: 'Scenario "' + scen.name + '" is complete!',
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        });
      }
    },
  },
};
