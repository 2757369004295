<template>
  <md-card class="side-panel-card layout-padding-none">
    <md-card-header class="layout-gray-dark">
      <div
        :id="`CoverIndicatorCard${indicatorType}`"
        class="md-title"
      >
        <i :class="cardIcon" />
        {{ cardTitle }}
      </div>
    </md-card-header>
    <div
      class="md-layout layout-gray"
      :class="{'larger-icons': indicatorType === 'Indigenous'}"
    >
      <cover-indicator
        v-for="_item in filteredIndicatorsByType(indicatorType)"
        :id="`CoverIndicator${_item.name.replace(/\s/g, '')}`"
        :key="_item.id"
        class="md-layout-item md-size-33 md-medium-size-33"
        :activity="activity"
        :scenario="scenario"
        :indicator="_item"
        :indicator-val="indicatorValue(_item.name, scenario, simulationState)"
        :large-icon="indicatorType === 'Indigenous'"
      />
    </div>
  </md-card>
</template>

<script>
import { Indicators } from "./mixins/Indicators";
import CoverIndicator from "./CoverIndicator";

export default {
  name: "CoverIndicatorCard",
  components: {
    CoverIndicator,
  },
  mixins: [Indicators], // common indicator related stuff
  props: {
    activity: {
      type: String,
      default: "view",
      validator: (prop) => [
        'view', // view existing scenario
        'create', // create new scenario
        'update', // update scenario
      ].includes(prop)
    },
    scenario: {
      type: Object,
      required: true,
    },
    simulationProgress: {
      type: Number,
      default: 0,
    },
    simulationState: {
      type: Object,
      required: true,
    },
    regionIndicators: {
      type: Array,
      required: true
    },
    indicatorType: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      simulationHelpers: { ...this.$Region.equationHelpers, ...this.$Helpers },
    };
  },
  computed: {
    cardTitle() {
      if (this.indicatorType === 'Environmental') {
        return `${this.indicatorType} Health`;
      } else if (this.indicatorType === 'Indigenous') {
        return `Indigenous Voices`;
      }
      return `${this.indicatorType} Performance`;
    },
    cardIcon() {
      if (this.indicatorType === 'Environmental') {
        return "fa fa-leaf";
      } else if (this.indicatorType === 'Indigenous') {
        return "fa fa-feather";
      }
      return "fa fa-users";
    }
  },
  methods: {
    filteredIndicatorsByType(type) {
      if (type === 'Indigenous') {
        // alternate / indigenous indicators have already been filtered before passing to this component. Just sort them
        return this.regionIndicators.sort((a, b) => a.order - b.order);
      }
      // else get either Environmental or Socio-Economic indicators
      return this.filteredIndicators(this.regionIndicators).filter(ind => ind.type === type);
    }
  }
}
</script>
<style lang="scss" scoped>
#scenario-wizard .side-panel-card {
  background-color: $white-color !important;
  box-shadow: none;
}
.larger-icons {
  padding-top: 12px;
  padding-bottom: 0px;
}
</style>